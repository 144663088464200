<template>
  <VueFileAgent
    ref="vueFileAgent"
    :theme="'list'"
    :multiple="true"
    :deletable="false"
    :meta="true"
    :readonly="true"
    :linkable="true"
    :accept="'image/*,video/*,.pdf,.doc,.docx,.ods,.xlsx,.xls'"
    :maxSize="maxSize ? maxSize : '10MB'"
    :maxFiles="14"
    helpText="Dosyaları seçin veya buraya sürükleyip bırakın"
    :errorText="{
      type: 'Bilinmeyen dosya tipi',
      size: 'Dosyalar 10MB den küçük olmalı'
    }"
    @select="filesSelected($event)"
    v-model="hwFiles"
  ></VueFileAgent>
</template>

<script>
export default {
  name: 'FileAgent',
  props: ['files', 'maxSize'],
  data: function () {
    return {
      uploadHeaders: { 'X-Test-Header': 'vue-file-agent' },
      fileRecordsForUpload: [] // maintain an upload queue
    }
  },
  computed: {
    hwFiles() {
      if (this.files) {
        return this.files
          .filter((s) => s.FilePublicUrl != null)
          .map((s) => ({
            name: s.FileName,
            size: s.Length,
            type: s.ContentType,
            url: s.FilePublicUrl
          }))
      } else {
        return []
      }
    }
  },
  methods: {
    filesSelected: function (fileRecordsNewlySelected) {
      var validFileRecords = fileRecordsNewlySelected.filter((fileRecord) => !fileRecord.error)
      this.fileRecordsForUpload = this.fileRecordsForUpload.concat(validFileRecords)
    }
  }
}
</script>
<style>
.vue-file-agent .file-preview {
  position: static;
}
</style>
