var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("VueFileAgent", {
    ref: "vueFileAgent",
    attrs: {
      theme: "list",
      multiple: true,
      deletable: false,
      meta: true,
      readonly: true,
      linkable: true,
      accept: "image/*,video/*,.pdf,.doc,.docx,.ods,.xlsx,.xls",
      maxSize: _vm.maxSize ? _vm.maxSize : "10MB",
      maxFiles: 14,
      helpText: "Dosyaları seçin veya buraya sürükleyip bırakın",
      errorText: {
        type: "Bilinmeyen dosya tipi",
        size: "Dosyalar 10MB den küçük olmalı",
      },
    },
    on: {
      select: function ($event) {
        return _vm.filesSelected($event)
      },
    },
    model: {
      value: _vm.hwFiles,
      callback: function ($$v) {
        _vm.hwFiles = $$v
      },
      expression: "hwFiles",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }