import Compressor from 'compressorjs'

const CompressionService = {
  async compressImage(image) {
    return await new Promise((resolve, reject) => {
      new Compressor(image, {
        convertTypes: ['image/png', 'image/webp'],
        convertSize: 500000,
        quality: 0.4,
        success: resolve,
        error: reject
      })
    })
  },

  async compressMultipleImages(images) {
    var promises = []
    for (const item of images) {
      const promise = await new Promise((resolve, reject) => {
        new Compressor(item, {
          convertTypes: ['image/png', 'image/webp'],
          convertSize: 500000, // if image size exceeds 0.5 MB, convert to jpeg
          quality: 0.4,
          success: resolve,
          error: reject
        })
      })
      promises.push(promise)
    }

    return new Promise((resolve, reject) => {
      if (promises.length === 0) {
        resolve([])
        return
      }

      const results = []
      let resolved = 0

      function collectResult(result, index) {
        results[index] = result
        resolved += 1
        if (resolved === promises.length) {
          resolve(results)
        }
      }

      promises.forEach((value, index) => {
        if (typeof value === 'object' && 'then' in value && typeof value.then === 'function') {
          value.then((res) => collectResult(res, index)).catch(reject)
        } else {
          Promise.resolve(value).then((res) => collectResult(res, index))
        }
      })
    })
  }
}

export default CompressionService
